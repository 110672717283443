import { useDetectClickOutside }    from 'react-detect-click-outside'
import { Icon }                     from 'assets/Icons_'
import { Container, Section, Mode } from './List.Caption.style'
import { _project }                 from 'state/store.projects'
import { getFocus, useGet, useSet }              from 'state/jotai'
import { active_project, vis_sorter }               from 'state/store.global'
import { useBreak }                 from 'hooks/useBreak'
import { Sort }                     from 'components/Sort2/Sort'
import { Box } from '@mui/material';
import { useFavoriteUnits } from 'components/Favorites/useFavoriteUnits'
import { useLocation } from 'react-router-dom'

export const Caption = ({count,mode,setMode,total}) => {

  const here = useLocation().pathname;
  const { units: favUnits } = useFavoriteUnits();
  
  const isDesktop = useBreak('md_up')
  const setState  = useSet(vis_sorter)
  const set       = (mode) => { return () => setMode(mode) }
  const outside   = { onTriggered: () => setState(false) }
  const ref       = useDetectClickOutside(outside)
  const onClick   = () => setState(a => !a)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
// console.log(buttonsLabel,"wert")
  const x = [
      // { name:'table', ico:'caption_icon_list', sz:20, clr:mode=='table'?'#000':'#aaa' },
    { name:'card' , ico:'newthreebar'       , sz:21, clr:mode=='card' ?'#000':'#aaa' },
    { name:'grid' , ico:'newcaption_icon_grid', sz:21, clr:mode=='grid' ?'#000':'#aaa' },
  ]

  const isFavoritePage = ["favorites"].some((i) => here.includes(i));
  
  return (
    <Container>
      <Section left>
        { x.map(({ name, ico, sz, clr })=> (
           <Mode onClick = {set(name)} mode = {mode} name = {name} key = {name} className='icon-hover-effect'>
          <Icon icon    = {ico} color      = {clr} size  = {sz}/>
          </Mode>
        ))}
      </Section>
      <Section className='paragraph1-regular' mid>
      { isFavoritePage ? 
        <Box sx = {{ px: 3, my: 3 }}>
          <span className='paragraph1-regular'>
          Du har&nbsp;</span>
         {count}
         <span className='paragraph1-regular'> {
            buttonsLabel?.filter(  (btn) => btn.UILabelProperty === "boliger" )[0]?.Label
          }</span>  
         <span className='paragraph1-regular'>&nbsp;som favoritter</span>
        </Box>
        :
        <span style = {{ whiteSpace: !isDesktop ? 'nowrap' : '' }}>
          {
            count ? <>
            {buttonsLabel?.filter((btn) => btn.UILabelProperty === "Viser")[0]?.Label} {" "}
            <strong>{count} </strong> 
            {buttonsLabel?.filter(  (btn) => {
              const label = count > 1 ? "boliger" : "bolig"
              return btn.UILabelProperty === label
            } )[0]?.Label}
            </>
            : 
            <>
            {buttonsLabel?.filter((btn) => btn.UILabelProperty === "Viser")[0]?.Label} {" "}
            <strong>{count} </strong> 
            {buttonsLabel?.filter(  (btn) => {
              const label = count > 1 ? "boliger" : "bolig"
              return btn.UILabelProperty === label
            } )[0]?.Label}
            </>
          }
        </span>
      }
      </Section>
      <Section right>
       <Sort />
      </Section>
    </Container>
  )
}