import { Box } from "@mui/material";
import UnitCard from "components/Unit/UnitCard";
import useUnitDrawer from "components/Unit/useUnitDrawer";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { active_project, fav_grid, params_query, previousUrl } from "state/store.global";
import { useFavoriteUnits } from "./useFavoriteUnits";
import { getFocus, useGet,get } from "state/jotai";
import { _project } from "state/store.projects";
  // FAVORITES
import ToolbarFavorites from "components/Favorites/Toolbar";
import { useBreak } from "hooks/useBreak";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import styled from "styled-components";
import { getpicks } from "utilities/utility.misc";
import { useClick } from "./Favorites.click";
import { Caption } from "components/List/List.Caption";
import { Content } from "components/List/List.style";
import { Card } from "components/List/Card/Card";
import { Grid } from './../List/Grid/Grid';
import { Loading2 } from "components/Loading2/Loading2";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import useUnitPage from "components/Unit/useUnitPage";
import CustomSnackbar from "components/ToastMessage/CustomSnackbarToast";

const Favorites = () => {
  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const navigateTo = useNavigate();
  const params       = useGet(params_query);
  const { units: favUnits } = useFavoriteUnits();
  const isDesktop           = useBreak("md_up");
  const { i18n: { language } } = useTranslation();
  const [refContainer] = useUnitPage();

  const [, setPreviousUrl]  = useAtom(previousUrl);

  const pick = { background: "#ff00ff" };
    // const unit         = useGet(active_unit)
  const units      = get(_project, "units", "Units", false);
  const raw        = get(_project, "unitsraw", "Units");
  const unitErrMsg = get(_project, "units", "message");
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const text  = get(_project, "cardtext");
  const picks = get(_project, "handpicked");
  const extra = getpicks(raw, picks);
  const count = favUnits?.length ?? [];

  const handleClick = (id) => {
    setPreviousUrl(location.pathname)
    navigateTo(`/${language}/unit/${id}${params.length ? params : ''}`);

    animateScroll.scrollToTop({
      container: refContainer,
      duration:500
    });
    log({ 
      action:'clicked_apartment_on_listview',
      unitId:id
    })
  };

  // Function to open Snackbar
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to close Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const total   = raw?.length ?? [];
  const mode    = useState("card");
  const click   = handleClick;
  const caption = { count, mode: mode[0], setMode: mode[1], total };
  const list    = { units: favUnits, extra, text, pick, unitErrMsg, click, mode: mode[0],snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose };

  return (
    <>
      {/* <Box sx = {{ height: 10 }} /> */}
      <Caption {...caption} />
      <Box style = {{ height: 10 }} />
      <div style = {{ border: "0px solid red" }}>
        {favUnits?.length ? (
          <Box
            sx={{
              display      : "flex",
              flexDirection: "column",
              minHeight    : {
                md: "calc(100vh - 200px)",
                xs: "calc(100vh - 250px)",
              },
              justifyContent: "space-between",
            }}
          >
            <Content units = {favUnits?.length} isDesktop = {isDesktop}>
              {/* { mode[0] === 'table' && <Table {...list}/> } */}
              {mode[0] === "card" && <Card {...list} />}
              {mode[0] === "grid" && <Grid {...list} />}
              {/* <Bottom>Powered by Estatetool, © {new Date().getFullYear()}</Bottom> */}
              <PoweredBy />
            </Content>
          </Box>
        ) : (
          <></>
        )}
      </div>
      {/* Snackbar */}
      <CustomSnackbar message={snackbarMessage} onClose={handleSnackbarClose} open={snackbarOpen}  />
      {/* <Box sx={{height: { md: '76px', xs: '87px' }}}/> */}
    </>
  );
};

Favorites.propTypes = {};

export default Favorites;

const Main = styled.div`
  grid-template-columns: repeat(auto-fit, minmax(327px, 1fr));
  display              : ${({ isGrid }) => isGrid ? "grid" : "block"};
  gap                  : 10px;
  justify-content      : center;
  align-items          : center;
  justify-items        : center;
  align-content        : center;
  background           : transparent;
  white-space          : nowrap;
  overflow             : hidden;
  textOverflow         : ellipsis;
`
const Item = styled.div`
  width           : 100%;
  cursor          : pointer;
  padding         : 0px 1px 0 1px;
  border-radius   : 6px;
  margin-bottom   : ${({ isGrid, favUnits, index }) => !isGrid && (index + 1 !== favUnits.length) ? "10px" : "0" };
  background-color: ${({ color }) => (() => color + '22')()};
                  : hover { background-color: ${({ color }) => (() => color + '33')()}; }
`



{/* <Box sx={{margin: {md:"0 16px" , xs:"0 16px"}}}>
<Grid container 
spacing = {{ xs: 2, sm:isGrid ? 2 :2, md: isGrid ? 6 : 2, lg:isGrid ?  6 : 2, xl:isGrid ?  4: 2, xxl:isGrid ? 4 : 2 }} justifyContent = "space-between"
>
  {favUnits.map((unit) => {
    return (
      <Grid
        item
        key = {unit.getId()}
        xs  = {12}
        sm  = {isGrid ? 6 : 12}
          // md={isGrid ? 6 : 12}
        lg = {isGrid ? 4 : 12}
        xl = {isGrid ? 3 : 12}
          // xxl={isGrid ? 3 : 12}
      >
        <UnitCard
          unit    = {unit}
          layout  = {isGrid ? "grid" : "list"}
          onClick = {handleClickCard}
        />
      </Grid>
    );
  })}
</Grid>
</Box> */}