import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box, CTA, Low, MoveDate, Separator, Status } from './Card.Content.style';
import { useBreak } from 'hooks/useBreak';
import { get, getMoveInDate } from 'components/List/List.Utilities';
import { Text } from './Card.Text';
import { Thumbnail } from './Card.Content.Thumbnail';
import { Icon } from 'assets/Icons_';
import { getFocus, useAtom, useGet } from 'state/jotai';
import { active_project, favorites } from 'state/store.global';
import { compares } from 'state/store.global';
import { _project } from 'state/store.projects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import CustomSnackbar from 'components/ToastMessage/CustomSnackbarToast';
import moment from 'moment';
import { IconBadge } from 'components/Icons';


// Custom Alert component to remove close icon and customize tick icon
// const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
//   return (
//     <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />
//   );
// });

export const Content = ({ j, childRef, maxHeight, unit, thumbnail, color, mode, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose }) => {



  const likes = useAtom(favorites)[0];
  const setLikes = useAtom(favorites)[1];
  const comps = useAtom(compares)[0];
  const setComps = useAtom(compares)[1];
  const show = useBreak('card').toString();
  const sID = unit.Data.UnitStatusID.Value;
  const moveindate = getMoveInDate(unit, "Indflytningsdato");
// console.log(moveindate,"getttttt")
  const todaydate = new Date().getTime();

  const isMobile = useBreak('md_dn')

  const share = { icon: 'share', color: clr?.alternative_condition, size: isMobile ? 15 : 15 }
  const compare = { icon: 'compare', color: clr?.compare, size: isMobile ? 25 : 25 }
  const compare_ = { icon: 'compare', color: clr?.compare, size: isMobile ? 25 : 25 }
  const heartempty = { icon: 'heart_empty', color: clr?.heart_empty, size: isMobile ? 28 : 28 }
  const heartfull = { icon: 'heart_full', color: clr?.heart_full, size: isMobile ? 28 : 28 }

  const isLiked = likes.includes(unit?.UnitID)
  const isComps = comps.includes(unit?.UnitID)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const today = new Date(moveindate);

  // Specify Danish as the locale and customize options
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const danishFormattedDate = moveindate;

  // console.log(danishFormattedDate,"datttww")
  const toggleLike = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...likes, unit.UnitID]
    const rem = [...likes].filter(i => i !== unit.UnitID)

    setLikes(isLiked ? rem : add)
    handleSnackbarOpen(isLiked ? buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastRemovedforFavorites"
    )[0]?.Label : buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastAddedtoFavorites"
    )[0]?.Label);
    
    log({
      action: isLiked
        ? 'removed_apartment_from_favorites'
        : 'added_apartment_to_favorites'
      , unitId: unit.UnitID
    })
  }

  const toggleComp = (e) => {
    if (sID == 40) { return }
    e.stopPropagation()
    const add = [...comps, unit.UnitID]
    const rem = [...comps].filter(i => i !== unit.UnitID)
    const max = comps.length == 3
    setComps(isComps ? rem : (max ? comps : add))
    // console.log(isComps, max, 'max')
    if (!isComps && max) { }
    else handleSnackbarOpen(isComps ? buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastRemovedforComparison"
    )[0]?.Label : buttonsLabel?.filter(
      (btn) => btn.UILabelProperty === "ToastAddedtoComparison"
    )[0]?.Label);
  }

  return (
    <>
      {show == 'true' && <Thumbnail thumbnail={thumbnail} />}
      <Box>
        <Text j={j} maxHeight={maxHeight} childRef={childRef} mode={mode} unit={unit} />
        {/* <div style={{height:12}}/> */}
        <Separator type={show} color={color} />
        {/* <div style={{height:8}}/> */}
        <Low type={show}>

          <CTA className='paragraph1-regular icon-hover-effect'  onClick={toggleComp}>
            <Icon size={'xl'} color='' {...isComps ? compare_ : compare} isFaIcon={true} count={isComps ? comps.indexOf(unit.UnitID) + 1 : 0} />
            {isComps ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Valgt')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label}`}
          </CTA>
          <CTA onClick={toggleLike} className='paragraph1-regular icon-hover-effect'>
            {
              show == 'true' ? <>
                 <IconBadge  {...isLiked ? heartfull : heartempty}   fontSize="20" />
                {/* <Icon {...isLiked ? heartfull : heartempty} /> */}
                
                {isLiked ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'GemBolig')[0]?.Label}`}
              </> : <>
               <IconBadge  {...isLiked ? heartfull : heartempty}   fontSize="20" />{isLiked ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'GemBolig')[0]?.Label}`}
              </>
            }
          </CTA>
        </Low>
        <Status mode={mode} className='h4' color={get(unit, 'UnitColor')}>{get(unit, 'UnitStatusName')}</Status>
        <MoveDate mode={mode} className='paragraph2-light ' color={get(unit, 'UnitColor')}>
          {sID === 10 && moveindate ? (
            moment(danishFormattedDate, 'DD.MM.YYYY').isBefore(moment()) ? (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
              </p>
            ) : (
              <p style={{ display: 'inline', fontSize: 12, opacity: 0.7 }}>
                ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {danishFormattedDate})
              </p>
            )
          ) : null}
        </MoveDate>
      </Box>

      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        style={{ 
          backgroundColor: '#1C6347', 
          zIndex: 9999, 
          position: 'absolute', 
          top: '0px', // Adjust top position as needed
          left: '50%', // Center the Snackbar horizontally
          transform: 'translateX(-50%)' // Center the Snackbar horizontally
        }} 
       
      >
        <CustomAlert
          elevation={6}
          variant="filled"
          // onClose={handleSnackbarClose}
          severity="success" // You can change the severity as needed
          sx={{ backgroundColor: '#1C6347', color: '#fff' }} // Customize background color and text color
          icon={<FontAwesomeIcon icon={faCircleCheck} />} // Replace the tick icon
          // action={null} // Remove the close icon
        >
          {snackbarMessage}
        </CustomAlert>
      </Snackbar> */}
    </>
  );
};
