import { useEffect, useState } from 'react'
import { Main, Control, Text } from './NoSnapSlider.style'
import { Thumb } from 'components/SliderThumb/SliderThumb'
import { Track } from 'components/SliderTrack/SliderTrack'
import { getFocus, useAtom, useGet, useSet } from 'state/jotai'
import { active_project, iso_isrotating, northDegree } from 'state/store.global'
import { vis_overlay } from 'state/store.global'
import { motion } from 'framer-motion'
import { _project } from 'state/store.projects'
import { useBreak } from 'hooks/useBreak'

export const NoSnapSlider = ({ frame, setFrame, config, index, setIndex }) => {

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop    = useBreak('md_up')
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, ease: 'linear' },
  }

  const ini = config.FIRST_IMAGE
  const max = config.FIRST_IMAGE + config.IMAGE_NUM - 1
  const end = config.IMAGE_NUM - 1
  const marks = config.IMAGE_SNAPFRAMES

  const [render, setRender] = useState(false)
  const setOverlay = useSet(vis_overlay)
  const setRotate = useSet(iso_isrotating)
  const change = v => setFrame(v)
  const northDeg = useAtom(northDegree)[0];

  const [deg, setDeg] = useState(northDeg)


  useEffect(()=> {
    if(northDeg > 360) {
      setDeg(Math.abs(360 - northDeg))
    }else setDeg(northDeg)
  }, [northDeg])

  useEffect(() => {
    setTimeout(() => {
      setRender(true)
    }, 2000)
    setOverlay(false)
    return () => {
      setOverlay(true)
    }
  }, [])

  if (!isNaN(end))
    return (
      <Main
        {...animation}
        onTouchStart={() => setRotate(false)}
        onTouchMove={() => setRotate(false)}
        // onMouseDown        = {()=>setRotate(false)}
        // onClick            = {()=>setRotate(false)}
        onMouseDownCapture={() => setRotate(false)}
      >
      <div className="h4" style={{ margin: "auto", marginBottom: "3rem" }}>{buttonsLabel?.filter( btn => btn?.UILabelProperty === 'onkserduselvatstyre')[0]?.Label}</div>
        { render && (
          <motion.div {...animation} style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <Text>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'AdministrerselvModel')[0]?.Label }</Text> */}
            <Control
            isDesktops={isDesktop}
              value={frame}
              defaultValue={frame}
              renderTrack={Track}
              renderThumb={(props)=> <Thumb props={props} northDeg={deg} />}
              step={1}
              max={max}
              min={ini}
              marks={[...marks]}
              onSliderClick={() => setRotate(false)}
              onChange={change}
            /> 
            {/* <span style={{position:!isDesktop ? "relative":"", top:!isDesktop?"-32px":"", left:!isDesktop?"-50px":""}} className='paragraph2-light'>{`${northDeg} ${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'grader')[0]?.Label}`}</span> */}
          </motion.div>
        )}
      </Main>
    )
}
